
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import React, { FC, useEffect, useState } from "react";
import axios from "axios";
// import { keycloak } from "keycloak";
import { createStyles, makeStyles } from "@material-ui/core";
import { Theme } from "react-select";
import ElementSizes from "Constants/ElementSizes";
import { keycloak } from "keycloak";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    powerBiFrame: {
      width: "100%",
      height: `calc(100% - ${ElementSizes.LOGO_HEIGHT}px)`,
    }
  }),
);

export const BiReport: FC = () => {
  const classes = useStyles();

  const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: 'report',
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Embed,
    settings: {
      panes: {
        filters: {
          visible: false
        }
      },
      background: models.BackgroundType.Default,
    }
  });

  useEffect(() => {
    // Add config to axios request when API is fully deployed
    const config = {
      headers: { Authorization: `Bearer ${keycloak?.token}` }
    };

    axios.get(`${process.env.REACT_APP_FILE_SERVER_URL}/powerbi/embedreport`, config).then((response: any) => {
      const data = response.data;

      setReportConfig({
        ...reportConfig,
        embedUrl: data.embedReport.embedUrl,
        accessToken: data.embedToken.token,
        id: data.embedReport.reportId
      });
    }).catch((error: any) => {
      console.warn(error);
    })
  // eslint-disable-next-line
  }, []);

  return (
    <PowerBIEmbed
      cssClassName={classes.powerBiFrame}
      embedConfig={reportConfig}
    />
  );
};
