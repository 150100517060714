import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import Colors from "Constants/Colors";
import DateConstants from "Constants/DateConstants";
import { CarEvidenceStatus } from "Enums/CarEvidenceStatus";
import { Roles } from "Enums/Roles";
import MaterialTable from "material-table";
import { CarEvidenceDocument } from "Models/CarEvidenceDocument";
import { Comment } from "Models/Comment";
import { InitializedComment } from "Models/InitialComment";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileUtils } from "Utils/FileUtils";
import { CarEvidenceComments } from "Views/Components/CarEvidenceDocuments/CarEvidenceComments";
import { CarEvidenceDocumentDetails } from "Views/Components/CarEvidenceDocuments/CarEvidenceDocumentDetails";
import { CarEvidenceDocumentUpload } from "Views/Components/CarEvidenceDocuments/CarEvidenceDocumentUpload";
import { DownloadCloudIcon } from "Views/Components/Icons/DownloadCloudIcon";
import { TableIcons } from "Views/Components/Icons/TableIcons";
import { UploadCloudIcon } from "Views/Components/Icons/UploadCloudIcon";

interface Props {
  readonly title: string;
  readonly carEvidenceDocuments: CarEvidenceDocument[];
  uploadNewDocument(document: CarEvidenceDocument, file: File): void;
  deleteDocument(document: CarEvidenceDocument): void;
  updateDocument(document: CarEvidenceDocument): void;
  insertComment(newComment: InitializedComment): void;
  updateComment(updatedComment: Comment): void;
  deleteComment(deletedComment: Comment): void;
  readonly isActive: boolean;
  readonly isLocked: boolean;
  readonly isArchived: boolean;
  handleRefetchCarDocuments():void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(1),
    },
    capitalise: {
      textTransform: "capitalize",
    },
    root: {
      backgroundColor: Colors.EVIDENCE_ITEM_SELECTED,
    },
  }),
);

export const CarEvidenceDocumentList: FC<Props> = ({
  title,
  carEvidenceDocuments,
  uploadNewDocument,
  deleteDocument,
  updateDocument,
  insertComment,
  updateComment,
  deleteComment,
  isActive,
  isLocked,
  isArchived,
  handleRefetchCarDocuments
}) => {
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation("cars");

  const [tableData, setTableData] = useState<CarEvidenceDocument[]>([]);

  const [uploadFormEnabled, setUploadFormEnabled] = useState(false);
  const [
    selectedCarEvidenceDocument,
    setSelectedCarEvidenceDocument,
  ] = useState<CarEvidenceDocument>();

  const handleAddClicked = () => {
    setUploadFormEnabled(!uploadFormEnabled);
  };

  const handleDocumentUpload = (document: CarEvidenceDocument, file: File) => {
    setUploadFormEnabled(false);
    uploadNewDocument(document, file);
  };

  const handleDocumentDelete = (document: CarEvidenceDocument) => {
    deleteDocument(document);
  };

  const handleEvidenceRowEdit = (document: CarEvidenceDocument) => {
    updateDocument(document);
  };

  const handleAddComment = (newComment: InitializedComment) => {
    insertComment(newComment);
  };

  const handleEditComment = (updatedComment: Comment) => {
    updateComment(updatedComment);
  };

  const handleDeleteComment = (deletedComment: Comment) => {
    deleteComment(deletedComment);
  };

  const handleRowDownloadClick = (
    rowData: CarEvidenceDocument | CarEvidenceDocument[],
  ) => {
    let url: string;
    let fileName: string;

    if (Array.isArray(rowData)) {
      url = rowData[0].evidenceDocumentReference;
      fileName = `${rowData[0].documentNumber}-${rowData[0].evidenceDocumentName}-${rowData[0].revision}`;
    } else {
      url = rowData.evidenceDocumentReference;
      fileName = `${rowData.documentNumber}-${rowData.evidenceDocumentName}-${rowData.revision}`;
    }

    if (url) {
      FileUtils.downloadFileByUrl(url, fileName, `bearer ${keycloak?.token}`);
    }
  };

  const handleCarEvidenceDocumentsReceived = useCallback(() => {
    const data: CarEvidenceDocument[] = [];
    carEvidenceDocuments?.forEach(item => {
      data.push({
        ...item,
      });
    });
    setTableData(data);
    handleRefetchCarDocuments();
        // eslint-disable-next-line
    }, [carEvidenceDocuments]);
  
      useEffect(() => {
        handleCarEvidenceDocumentsReceived();
          // eslint-disable-next-line
      }, [carEvidenceDocuments]);

  return (
    <>
      {uploadFormEnabled ? (
        <Grid item xs={12} className={classes.addButtonRow}>
          <Tooltip title={t<string>("files:tooltipDiscard")} arrow={true}>
            <IconButton onClick={handleAddClicked}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        (keycloak?.hasRealmRole(Roles.auditor) ||
          keycloak?.hasRealmRole(Roles.operator) ||
          keycloak?.hasRealmRole(Roles.agency) ||
          keycloak?.hasRealmRole(Roles.agency_v2) ||
          keycloak?.hasRealmRole(Roles.administrator)) &&
        isActive &&
        isLocked === false &&
        !isArchived && (
          <Grid item xs={12} className={classes.addButtonRow}>
            <Tooltip title={t<string>("files:tooltipAddEvidence")} arrow={true}>
              <Button onClick={handleAddClicked} className={classes.capitalise}>
                <UploadCloudIcon />
              </Button>
            </Tooltip>
          </Grid>
        )
      )}
      {uploadFormEnabled && (
        <CarEvidenceDocumentUpload
          handleDocumentUpload={handleDocumentUpload}
        />
      )}
      <MaterialTable
        options={{
          search: true,
          emptyRowsWhenPaging: false,
          detailPanelColumnAlignment: "right",
          detailPanelType: "single",
          paging: true,
          rowStyle: rowData => ({
            backgroundColor:
              selectedCarEvidenceDocument &&
              selectedCarEvidenceDocument.id === rowData.id
                ? Colors.EVIDENCE_ITEM_SELECTED
                : "",
          }),
        }}
        onRowClick={(event, rowData, togglePanel) => {
          setSelectedCarEvidenceDocument(rowData);
          if (togglePanel) {
            togglePanel();
          }
        }}
        title={title}
        icons={TableIcons}
        data={tableData}
        columns={[
          {
            title: t("evidenceStatus"),
            field: "evidenceStatus",
            render: rowData => (
              <>
                {rowData.evidenceStatus === CarEvidenceStatus.awaitingReview &&
                  t("cars:evidenceStatusAwaitingReview")}
                {rowData.evidenceStatus === CarEvidenceStatus.anpgAccepted &&
                  t("cars:evidenceStatusAnpgAccepted")}
                {rowData.evidenceStatus ===
                  CarEvidenceStatus.acceptedWithComment &&
                  t("cars:evidenceStatusAcceptedWithComment")}
                {rowData.evidenceStatus ===
                  CarEvidenceStatus.evidenceReviewed &&
                  t("cars:evidenceStatusEvidenceReviewed")}
                {rowData.evidenceStatus ===
                  CarEvidenceStatus.requiresOperatorReview &&
                  t("cars:evidenceStatusRequiresOperatorReview")}
              </>
            ),
          },
          { title: t("doc_name"), field: "evidenceDocumentName" },
          { title: t("submitter"), field: "submitter.fullName" },
          {
            title: t("dateAdded"),
            field: "createdAt",
            render: rowData => (
              <>
                {moment(rowData.createdAt).format(
                  DateConstants.DEFAULT_DATE_FORMAT,
                )}
              </>
            ),
          },
          {
            title: t("lastUpdated"),
            field: "updatedAt",
            render: rowData => (
              <>
                {moment(rowData.updatedAt).format(
                  DateConstants.TIME_DATE_FORMAT,
                )}
              </>
            ),
          },
        ]}
        actions={[
          {
            icon: () => <DownloadCloudIcon />,
            onClick: (event, rowData) => {
              if (rowData) {
                handleRowDownloadClick(rowData);
              }
            },
            tooltip: t("files:downloadCarEvidence"),
          },
        ]}
        localization={{
          header: {
            actions: " ",
          },
        }}
        detailPanel={documentData => {
          return (
            <Grid container className={classes.root}>
              <CarEvidenceDocumentDetails
                carEvidenceDocument={documentData}
                handleDocumentDeleted={handleDocumentDelete}
                handleEvidenceRowEdited={handleEvidenceRowEdit}
                isActive={isActive}
                isLocked={isLocked}
                isArchived={isArchived}
              />
              <CarEvidenceComments
                carEvidenceDocument={documentData}
                handleCommentAdded={handleAddComment}
                handleCommentEdited={handleEditComment}
                handleCommentDeleted={handleDeleteComment}
                isActive={isActive}
                isLocked={isLocked}
                isArchived={isArchived}
              />
            </Grid>
          );
        }}
      />
    </>
  );
};
